import payload_plugin_qLmFnukI99 from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/frc_nuxt3/frc_nuxt3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_NxDDSatxox from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import plugin_adVF2uRka6 from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_iGLjBbor9q from "/home/runner/work/frc_nuxt3/frc_nuxt3/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import analytics_client_s6XgHMr3Q1 from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/plugins/analytics.client.js";
import aos_vL4LgONfVX from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/plugins/aos.js";
import confirm_gdALVVrTAq from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/plugins/confirm.js";
import dayjs_ygUzRB9T0M from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/plugins/dayjs.js";
import maska_Fc2SmiNCX8 from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/plugins/maska.js";
import recaptcha_nZR2jxt8pt from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/plugins/recaptcha.js";
import toastify_HzF3oJ6zco from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/plugins/toastify.js";
import vuetify_QwmrB3LYDa from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/plugins/vuetify.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  session_client_NxDDSatxox,
  plugin_adVF2uRka6,
  plugin_iGLjBbor9q,
  analytics_client_s6XgHMr3Q1,
  aos_vL4LgONfVX,
  confirm_gdALVVrTAq,
  dayjs_ygUzRB9T0M,
  maska_Fc2SmiNCX8,
  recaptcha_nZR2jxt8pt,
  toastify_HzF3oJ6zco,
  vuetify_QwmrB3LYDa
]