import { default as our_45storym10clHB6NBMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/our-story.vue?macro=true";
import { default as plan_45your_45visitd37w0zY3JpMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/plan-your-visit.vue?macro=true";
import { default as what_45we_45believeI4D0QfnU6nMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/what-we-believe.vue?macro=true";
import { default as contactfjCedkOW7bMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/contact.vue?macro=true";
import { default as what_45is_45the_45gospel3hC2WeXNHwMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/faith/what-is-the-gospel.vue?macro=true";
import { default as forgot_45passwordL858Wvy0SaMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/forgot-password.vue?macro=true";
import { default as giveJLK8D7SXpqMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/give.vue?macro=true";
import { default as indexlFv83rotiWMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/index.vue?macro=true";
import { default as loginmFKDUbWLi0Meta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/login.vue?macro=true";
import { default as indexsBxfBSLONNMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/calendar/index.vue?macro=true";
import { default as _91id_93FBGuqGYxBYMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/collections/[id].vue?macro=true";
import { default as indexN840ZEoArYMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/collections/index.vue?macro=true";
import { default as indexWfXIuTG9FZMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/index.vue?macro=true";
import { default as my_45accountqY7epXKXATMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/my-account.vue?macro=true";
import { default as _91user_id_93Yem19cEETPMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users/[user_id].vue?macro=true";
import { default as indexGkQPRLmyrSMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users/index.vue?macro=true";
import { default as usershqozQA3TPAMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users.vue?macro=true";
import { default as childrengKfJA1q4zeMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/children.vue?macro=true";
import { default as day_45campUxs5xzyhTMMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/day-camp.vue?macro=true";
import { default as discipleshipkoSEzVscgRMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/discipleship.vue?macro=true";
import { default as youthalwRFui4bqMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/youth.vue?macro=true";
import { default as password_45resetOjMMlAjbtdMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/password-reset.vue?macro=true";
import { default as privacyPCojaRxANUMeta } from "/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/privacy.vue?macro=true";
export default [
  {
    name: "about-our-story",
    path: "/about/our-story",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/our-story.vue")
  },
  {
    name: "about-plan-your-visit",
    path: "/about/plan-your-visit",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/plan-your-visit.vue")
  },
  {
    name: "about-what-we-believe",
    path: "/about/what-we-believe",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/about/what-we-believe.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/contact.vue")
  },
  {
    name: "faith-what-is-the-gospel",
    path: "/faith/what-is-the-gospel",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/faith/what-is-the-gospel.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/forgot-password.vue")
  },
  {
    name: "give",
    path: "/give",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/give.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/login.vue")
  },
  {
    name: "members-calendar",
    path: "/members/calendar",
    meta: indexsBxfBSLONNMeta || {},
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/calendar/index.vue")
  },
  {
    name: "members-collections-id",
    path: "/members/collections/:id()",
    meta: _91id_93FBGuqGYxBYMeta || {},
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/collections/[id].vue")
  },
  {
    name: "members-collections",
    path: "/members/collections",
    meta: indexN840ZEoArYMeta || {},
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/collections/index.vue")
  },
  {
    name: "members",
    path: "/members",
    meta: indexWfXIuTG9FZMeta || {},
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/index.vue")
  },
  {
    name: "members-my-account",
    path: "/members/my-account",
    meta: my_45accountqY7epXKXATMeta || {},
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/my-account.vue")
  },
  {
    name: usershqozQA3TPAMeta?.name,
    path: "/members/users",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users.vue"),
    children: [
  {
    name: "members-users-user_id",
    path: ":user_id()",
    meta: _91user_id_93Yem19cEETPMeta || {},
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users/[user_id].vue")
  },
  {
    name: "members-users",
    path: "",
    meta: indexGkQPRLmyrSMeta || {},
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/members/users/index.vue")
  }
]
  },
  {
    name: "ministries-children",
    path: "/ministries/children",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/children.vue")
  },
  {
    name: "ministries-day-camp",
    path: "/ministries/day-camp",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/day-camp.vue")
  },
  {
    name: "ministries-discipleship",
    path: "/ministries/discipleship",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/discipleship.vue")
  },
  {
    name: "ministries-youth",
    path: "/ministries/youth",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/ministries/youth.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/password-reset.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/home/runner/work/frc_nuxt3/frc_nuxt3/src/pages/privacy.vue")
  }
]